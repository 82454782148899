import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUsContentTemplate from "../templates/about-us/about-us-content-template"
import Hero01 from '../templates/home/hero-01-template'
import {Helmet} from 'react-helmet'


const AboutUs = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page" data-active-page="page-about-us" />
      </Helmet>
      <SEO title="About Us" />
      {/* <HeroImgSubPage /> */}
      <Hero01 />
      <section className="content-container">
        <AboutUsContentTemplate />
      </section>
    </Layout>
  </>
)

export default AboutUs
